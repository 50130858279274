import { render, staticRenderFns } from "./AdminNavigator.vue?vue&type=template&id=07b3b44b&scoped=true"
import script from "./AdminNavigator.vue?vue&type=script&lang=js"
export * from "./AdminNavigator.vue?vue&type=script&lang=js"
import style0 from "./AdminNavigator.vue?vue&type=style&index=0&id=07b3b44b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b3b44b",
  null
  
)

export default component.exports