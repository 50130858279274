<template>
	<div class="noselect checkout">
		<v-card-title class="subtitle-2 pb-2 title-text overflow">
			<v-icon small class="mr-2">mdi-cart-outline</v-icon>
			Shopping Cart
		</v-card-title>
		<v-divider class="mx-4" dark></v-divider>
		<v-hover v-slot="{ hover }">
			<v-list dense color="background_dark2">
				<v-list-item
					dense
					v-ripple="hover ? false : { class: `primary--text` }"
					v-for="item in cart"
					:key="item.id"
					:ref="itemRef(item)"
					@mouseover="showDelete(item, true)"
					@mouseleave="showDelete(item, false)"
				>
					<v-list-item-icon class="mr-1 row-icon">
						<v-expand-x-transition>
							<v-btn
								v-if="hasDelete[item.id]"
								class="delete-button mr-1"
								color="error"
								dark
								depressed
								width="19"
								height="19"
								icon
								@click="removeFromCart(item)"
							>
								<v-icon dark size="18">mdi-trash-can-outline</v-icon>
							</v-btn>
						</v-expand-x-transition>
						<v-img
							contain
							:alt="item.id"
							:src="getImageSrc(item)"
							height="18"
							width="18"
						/>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="caption">
							{{ item.text }}
						</v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<div class="count-column">
							<span>${{ item.price }} x</span>
							<input
								:value="item.count"
								@input="countUpdate($event, item)"
								@blur="countBlur($event, item)"
								:ref="itemRef(item) + '-count'"
								class="count-field"
								:style="calculateWidth(item)"
								number
								lazy
							/>
						</div>
					</v-list-item-action>
				</v-list-item>
			</v-list>
		</v-hover>
		<v-divider class="mx-4" dark></v-divider>
		<v-card-title class="subtitle-1 overflow total-text">
			Total = ${{ totalCost }}.00 USD
		</v-card-title>
		<span v-if="shopDisabled">
			<v-card-subtitle class="subtitle-1 temp-text">
				We are currently having issues with payments. Please check back another
				time!
			</v-card-subtitle>
		</span>
		<span v-else-if="loggedIn">
			<ShopCheckoutGiftButton class="mx-4 mb-4" />
			<v-divider class="mx-4 mb-4" dark></v-divider>
			<!-- <ShopCheckoutPaymentCloudButton class="mb-2"/> -->
			<ShopCheckoutCloverButton class="mb-2" />
			<ShopCheckoutCoinbaseButton class="mb-3" />
		</span>
		<v-card-title v-else class="subtitle-2 overflow total-text">
			Please log in to checkout
		</v-card-title>
	</div>
</template>

<script>
import ShopCheckoutGiftButton from '@/views/store/components/ShopCheckoutGiftButton';
// import ShopCheckoutPaymentCloudButton from '@/views/store/components/ShopCheckoutPaymentCloudButton'
import ShopCheckoutCloverButton from '@/views/store/components/ShopCheckoutCloverButton';
// import ShopCheckoutStripeButton from '@/views/store/components/ShopCheckoutStripeButton';
import ShopCheckoutCoinbaseButton from '@/views/store/components/ShopCheckoutCoinbaseButton';

export default {
	name: 'ShopCheckout',
	components: {
		ShopCheckoutGiftButton,
		ShopCheckoutCloverButton,
		ShopCheckoutCoinbaseButton,
	},
	data: () => ({
		hasDelete: {},
	}),
	computed: {
		cart() {
			return this.$store.getters.cart;
		},
		totalCost() {
			return this.$store.getters.totalCost;
		},
		loggedIn() {
			return !!this.$store.getters.user?.id;
		},
		shopDisabled() {
			return this.$store.getters.shopDisabled;
		},
	},
	methods: {
		getImageSrc(item) {
			if (item.icon) {
				return require(`@/assets/` + item.icon);
			} else if (item.emoji) {
				return this.getEmojiUrl(item.emoji);
			}
		},
		itemRef(item) {
			return item.id + '-item';
		},
		itemAdded(item) {
			const refId = this.itemRef(item);
			const ref = this.$refs[refId];
			if (!ref?.[0]) return;
			const el = ref[0].$el;
			const ev = new Event('mousedown');
			const offset = el.getBoundingClientRect();
			ev.clientX = offset.left + offset.width / 2;
			ev.clientY = offset.top + offset.height / 2;
			el.dispatchEvent(ev);
			setTimeout(function () {
				el.dispatchEvent(new Event('mouseup'));
			});
		},
		countUpdate(ele, item) {
			let count = ele.target.value.replace(/\D/gi, '');
			count = parseInt(count);
			if (!count) count = '';
			this.$store.dispatch('setItemCount', { item, count });
		},
		countBlur(ele, item) {
			let count = ele.target.value.replace(/\D/gi, '');
			count = parseInt(count);
			if (!count || count <= 0) {
				this.$store.dispatch('removeFromCart', item);
			} else {
				this.$store.dispatch('setItemCount', { item, count });
			}
		},
		removeFromCart(item) {
			this.$store.dispatch('removeFromCart', item);
		},
		calculateWidth(item) {
			let width = ('' + item.count).length * 7;
			return { width: width + 'px' };
		},
		showDelete(item, show) {
			if (show) {
				this.$set(this.hasDelete, item.id, true);
			} else {
				this.$set(this.hasDelete, item.id, false);
			}
		},
	},
};
</script>

<style scoped>
.checkout {
	overflow: hidden;
}

.row-icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.count-column {
	font-size: 0.75rem !important;
	letter-spacing: 0.0333333333em !important;
	font-family: 'Roboto', sans-serif !important;
	font-weight: 500;
	line-height: 1rem;
	color: white;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.count-field {
	color: white;
	width: 22px;
	overflow: visible;
	outline: 0px;
}

.overflow {
	white-space: nowrap;
	display: inline-block;
}

.total-text {
	text-align: right;
	width: 100%;
}

.login-text {
	text-align: center;
	width: 100%;
}

.temp-text {
	text-align: center;
	width: 100%;
}
</style>
