<template>
	<vue-particles
		:class="fullscreen ? 'pfull' : 'psmall'"
		id="tsparticles"
		:options="options"
	/>
</template>

<script>
export default {
	name: 'ParticalBackground',
	props: {
		fullscreen: {
			type: Boolean,
		},
	},
	data: () => ({
		options: {
			style: {
				position: 'absolute',
			},
			fpsLimit: 30,
			interactivity: {
				detect_on: 'canvas',
				events: {
					resize: true,
					onClick: {
						enable: true,
						mode: 'push',
					},
				},
			},
			modes: {
				attract: {
					distance: 200,
					duration: 0.4,
					easing: 'ease-out-quad',
					factor: 1,
					maxSpeed: 50,
					speed: 1,
				},
				bounce: {
					distance: 200,
				},
				bubble: {
					distance: 400,
					duration: 2,
					opacity: 0.8,
					size: 40,
				},
				connect: {
					distance: 80,
					links: {
						opacity: 0.5,
					},
					radius: 60,
				},
				grab: {
					distance: 400,
					links: {
						blink: false,
						consent: false,
						opacity: 1,
					},
				},
				light: {
					area: {
						gradient: {
							start: {
								value: '#ffffff',
							},
							stop: {
								value: '#000000',
							},
						},
						radius: 1000,
					},
					shadow: {
						color: {
							value: '#000000',
						},
						length: 2000,
					},
				},
				push: {
					quantity: 4,
				},
				remove: {
					quantity: 2,
				},
				repulse: {
					distance: 200,
					duration: 0.4,
					factor: 100,
					speed: 1,
					maxSpeed: 50,
					easing: 'ease-out-quad',
				},
				slow: {
					factor: 3,
					radius: 200,
				},
				trail: {
					delay: 1,
					pauseOnStop: false,
					quantity: 1,
				},
			},
			particles: {
				color: {
					value: ['#7DBCEB', '#FFDA00'],
				},
				move: {
					direction: 'none',
					enable: true,
					random: true,
					speed: 3.1,
					straight: false,
				},
				bounce: {
					horizontal: {
						random: {
							enable: false,
							minimumValue: 0.1,
						},
						value: 1,
					},
					vertical: {
						random: {
							enable: false,
							minimumValue: 0.1,
						},
						value: 1,
					},
				},
				collisions: {
					bounce: {
						horizontal: {
							random: {
								enable: false,
								minimumValue: 0.1,
							},
							value: 1,
						},
						vertical: {
							random: {
								enable: false,
								minimumValue: 0.1,
							},
							value: 1,
						},
					},
					enable: true,
					mode: 'bounce',
					overlap: {
						enable: true,
						retries: 0,
					},
				},
				rotate: {
					animation: {
						enable: true,
						speed: 1.5,
						sync: false,
					},
				},
				number: {
					density: {
						enable: true,
					},
					value: 200,
				},
				shape: {
					options: {
						char: [
							{
								fill: true,
								font: 'Verdana',
								style: '',
								value: 'owo'.split(''),
								weight: '400',
							},
						],
					},
					type: 'char',
				},
				size: {
					value: { min: 8, max: 30 },
				},
				opacity: {
					random: true,
					value: {
						min: 0.05,
						max: 0.5,
					},
				},
			},
		},
		particle: null,
	}),
};
</script>

<style scoped>
#tsparticles {
	z-index: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	touch-action: none;
}

.psmall {
	position: absolute;
}

.pfull {
	position: fixed;
	top: 0px;
	overflow: hidden;
}
</style>
