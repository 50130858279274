import axios from 'axios';

const actions = {
	getReportCategories: async () => {
		const result = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/report/categories`,
			{ withCredentials: true }
		);
		return result.data;
	},
	submitReport: (v, { files, form }) => {
		const blob = new Blob([JSON.stringify(form)], { type: 'application/json' });
		const formData = new FormData();
		formData.append('form', blob);
		for (let i = 0; i < files.length; i++) {
			formData.append(`images[${i}]`, files[i]);
		}
		return axios.post(
			process.env.VUE_APP_BACKEND + '/api/report/upload',
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
				withCredentials: true,
			}
		);
	},
	getUserReports: async (v, { page, limit, userId, showAlts }) => {
		const resp = await axios.get(
			`${
				process.env.VUE_APP_BACKEND
			}/api/report/users?page=${page}&limit=${limit}&userId=${
				userId || ''
			}&showAlts=${showAlts}`,
			{
				withCredentials: true,
			}
		);
		return resp.data;
	},
	getUserReport: async (v, id) => {
		const resp = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/report/user/${id}`,
			{
				withCredentials: true,
			}
		);
		return resp.data;
	},
	getTotalUserReports: async () => {
		const resp = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/report/total-users`,
			{
				withCredentials: true,
			}
		);
		return resp.data;
	},
	getGuildReports: async (v, { page, limit, guildId }) => {
		const resp = await axios.get(
			`${
				process.env.VUE_APP_BACKEND
			}/api/report/guilds?page=${page}&limit=${limit}&guildId=${guildId || ''}`,
			{
				withCredentials: true,
			}
		);
		return resp.data;
	},
	getGuildReport: async (v, id) => {
		const resp = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/report/guild/${id}`,
			{
				withCredentials: true,
			}
		);
		return resp.data;
	},
	getTotalGuildReports: async () => {
		const resp = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/report/total-guilds`,
			{
				withCredentials: true,
			}
		);
		return resp.data;
	},
};

export default { actions };
