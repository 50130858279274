<template>
	<div>
		<div :class="{ dropbox: true, 'drag-hover': dragClass }" v-if="!file">
			<input
				type="file"
				:name="file ? file.name : 'photo'"
				@change="fileAdded($event.target.files)"
				@drop.prevent="
					dragClass = null;
					fileAdded($event.dataTransfer.files);
				"
				@dragover.prevent="dragClass = true"
				@dragleave="dragClass = null"
				accept="image/*"
				class="input-file"
			/>
			<p class="ma-0">
				Drag your image here <br />
				or click to browse
			</p>
		</div>
		<div class="preview" v-else>
			<v-img
				ref="image"
				class="preview-image"
				contain
				max-height="200px"
				:src="imageOverlay || fileSrc"
			>
				<v-btn
					small
					@click="removeFile"
					class="remove-button"
					icon
					color="error"
				>
					<v-icon small>mdi-close</v-icon>
				</v-btn>
			</v-img>
			<div class="preview-info">
				<div>{{ file.name }}</div>
				<div>{{ formattedFileSize }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FileUpload',
	props: {
		maxSize: {
			type: Number,
			default: 5 * 1024 * 1024,
		},
		imageOverlay: {
			type: String,
			required: false,
		},
		sizeOverride: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({
		file: null,
		fileSrc: null,
		dragClass: null,
	}),
	computed: {
		formattedFileSize() {
			return (
				this.sizeToText(this.sizeOverride || this.file.size) +
				' / ' +
				this.sizeToText(this.maxSize)
			);
		},
	},
	methods: {
		sizeToText(size) {
			const mb = 1024 * 1024;
			const kb = 1024;
			if (size > mb) {
				return Math.round((size / mb) * 10) / 10 + 'MB';
			} else if (size > kb) {
				return Math.round((size / kb) * 10) / 10 + 'KB';
			} else {
				return size + 'B';
			}
		},
		fileAdded(files) {
			const file = files[0];

			if (!file) {
				const opt = {
					text: "That's an invalid file! Try a different one!",
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			if (!file.type || !/image\/\w+/gi.test(file.type)) {
				const opt = {
					text: "That's an invalid file! Files must be an image type",
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			if (file.size > this.maxSize) {
				const opt = {
					text: `That file is too big! Files must be under ${this.sizeToText(
						this.maxSize
					)}.`,
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}

			this.file = file;
			this.$emit('file', file);
			this.getFileSrc();
		},
		getFileSrc() {
			if (!this.file) {
				this.$emit('fileSrc', null);
				return;
			}
			const reader = new FileReader();
			reader.onload = (e) => {
				this.fileSrc = e.target.result;
				this.$emit('fileSrc', { file: this.file, src: this.fileSrc });
			};
			reader.readAsDataURL(this.file);
		},
		removeFile() {
			this.file = null;
			this.fileSrc = null;
			this.$emit('file', null);
			this.$emit('fileSrc', null);
		},
	},
};
</script>

<style scoped lang="scss">
.dropbox {
	outline: 2px dashed var(--v-primary-base); /* the dash box */
	background: var(--v-background-darken1);
	outline-offset: -10px;
	color: white;
	min-height: 200px; /* minimum height */
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.5s ease;
}

.input-file {
	opacity: 0; /* invisible but it's there! */
	width: 100%;
	height: 200px;
	position: absolute;
	cursor: pointer;
}

.dropbox:hover {
	outline-offset: -5px;
	background: var(--v-background-base);
}

.dropbox p {
	font-size: 1.2em;
	text-align: center;
	padding: 50px 0;
}

.preview {
	background: var(--v-background_dark-base);
	padding: 10px 10px;
	border-radius: 8px;
}

.preview-image {
	position: relative;
}

.remove-button {
	position: absolute;
	top: 5px;
	right: 5px;
}

.preview-info {
	display: flex;
	justify-content: space-between;
	padding-top: 5px;
}

.drag-hover {
	outline-offset: -5px !important;
	background: var(--v-background-base) !important;
}
</style>
