<template>
	<div>
		<FormColor />
		<FormBody />
		<FormSideImage />
		<FormBottomImage />
		<FormTitle />
		<FormFooter />
		<FormAuthor />
		<FormAvatar />
	</div>
</template>

<script>
import FormColor from '@/views/customize/components/form/FormColor';
import FormBody from '@/views/customize/components/form/FormBody';
import FormSideImage from '@/views/customize/components/form/FormSideImage';
import FormBottomImage from '@/views/customize/components/form/FormBottomImage';
import FormTitle from '@/views/customize/components/form/FormTitle';
import FormFooter from '@/views/customize/components/form/FormFooter';
import FormAvatar from '@/views/customize/components/form/FormAvatar';
import FormAuthor from '@/views/customize/components/form/FormAuthor';

export default {
	name: 'HuntForm',
	components: {
		FormColor,
		FormBody,
		FormSideImage,
		FormBottomImage,
		FormTitle,
		FormFooter,
		FormAvatar,
		FormAuthor,
	},
};
</script>

<style scoped></style>
