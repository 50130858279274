<template>
	<span>
		<div class="table">
			<v-card-title>
				<v-avatar size="32" class="avatar">
					<v-img :src="avatarUrl" alt="avatar" />
				</v-avatar>
				{{ title }}
			</v-card-title>
			<v-data-table
				v-if="isLoggedIn"
				:headers="headers"
				:items="pets"
				:footer-props="footerProps"
				:options.sync="options"
				sort-by="updated"
				sort-desc
			>
				<template v-slot:item.image="{ item }">
					<v-img :src="item.image" width="50" contain></v-img>
					<div v-html="item.title"></div>
				</template>
				<template v-slot:item.status="{ item }">
					<v-tooltip right :disabled="!item.reason">
						<template v-slot:activator="{ on, attrs }">
							<v-chip :color="item.statusColor" dark v-on="on" v-bind="attrs">
								{{ item.status }}
							</v-chip>
						</template>
						<span><b>Reason:</b> {{ item.reason }}</span>
					</v-tooltip>
				</template>
				<template v-slot:item.updated="{ item }">
					{{ new Date(item.updated).toLocaleString() }}
				</template>
				<template v-slot:item.action="{ item }">
					<v-btn color="primary" text @click="showInfo(item)">
						<v-icon> mdi-eye-outline </v-icon>
					</v-btn>
					<BattleLogAnimalInfo
						:animal="item.animalInfoData"
						:overrideShow="item.show"
						@close="item.show = false"
					/>
				</template>
			</v-data-table>
		</div>
		<v-card-actions class="action-row">
			<div class="ticket-row">
				<v-img
					contain
					alt="pticket"
					:src="require(`@/assets/pticket.png`)"
					width="32"
				/>
				{{ ticketCount }} tickets available
			</div>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				depressed
				:disabled="!ticketCount"
				:to="createLink"
			>
				+ Create Pet
			</v-btn>
		</v-card-actions>
	</span>
</template>

<script>
import endpoints from '@/router/routes.js';
import BattleLogAnimalInfo from '@/views/battle-logs/components/BattleLogAnimalInfo';

export default {
	name: 'CustomPetTable',
	components: { BattleLogAnimalInfo },
	data: () => ({
		options: {
			page: 1,
			itemsPerPage: 25,
		},
		footerProps: {
			'items-per-page-options': [25, 50, 100],
		},
		headers: [
			{ text: '', value: 'image', sortable: false },
			{ text: 'Pet Name', value: 'name', sortable: true },
			{ text: 'Status', value: 'status', sortable: true },
			{ text: 'Updated', value: 'updated', sortable: true },
			{ text: '', value: 'action', sortable: false },
		],
		createLink: endpoints.CUSTOM_PET_CREATE,
	}),
	computed: {
		pets() {
			const pending = this.$store.getters.customPending;
			const result = [];
			pending.forEach((pet) => {
				let status = 'Pending';
				let statusColor = '';
				if (pet.success) {
					status = 'Approved';
					statusColor = '#63b463';
				} else if (pet.decline) {
					status = 'Declined';
					statusColor = '#b46363';
				}
				result.push({
					name: pet.name,
					image: this.getPetImageUrl(pet),
					status,
					statusColor,
					reason: pet.declineReason,
					updated: new Date(pet.updatedAt).valueOf(),
					animalInfoData: this.parseAnimalInfoData(pet),
				});
			});
			return result;
		},
		title() {
			return `${this.user.fullName}'s Custom Pets`;
		},
		ticketCount() {
			return this.$store.getters.customPetTickets;
		},
		avatarUrl() {
			if (this.user.avatar) {
				return `https://cdn.discordapp.com/avatars/${this.user.id}/${this.user.avatar}.png`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(this.user.discriminator) % 5
				}.png`;
			}
		},
		user() {
			return this.$store.getters.user;
		},
		isLoggedIn() {
			return !!this.$store.getters.user?.id;
		},
	},
	methods: {
		getPetImageUrl(pet) {
			return `${process.env.VUE_APP_CDN}/cdn/custom-pet/pending/${pet._id}.${pet.image.ext}`;
		},
		showInfo(pet) {
			this.$set(pet, 'show', true);
		},
		parseAnimalInfoData(pet) {
			let creators = 'None';
			if (pet.creators.length) {
				creators = pet.creators
					.map((user) => {
						return '@' + user.username;
					})
					.join(', ');
			}
			let gifts = 'None';
			if (pet.gifts.length) {
				gifts = pet.gifts
					.map((user) => {
						return '@' + user.username;
					})
					.join(', ');
			}
			let color = '';
			if (pet.success) {
				color = 'var(--v-success-base)';
			} else if (pet.decline) {
				color = 'var(--v-error-base)';
			} else {
				color = 'var(--v-primary-base)';
			}
			return {
				custom: true,
				name: pet.name,
				desc: pet.description,
				owner: '@' + pet.by.username,
				creators,
				gifts,
				alt: pet.alt.join(', ') || 'None',
				rank: 'cpatreon',
				hpr: pet.hp,
				attr: pet.att,
				prr: pet.pr,
				wpr: pet.wp,
				magr: pet.mag,
				mrr: pet.mr,
				urlOverride: this.getPetImageUrl(pet),
				styleOverride: { 'border-color': `${color} !important` },
			};
		},
	},
	async mounted() {},
};
</script>

<style scoped>
.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.table {
	width: 100%;
	height: 100%;
}

.avatar {
	margin-right: 10px;
}

::v-deep .small-emoji {
	width: 18px;
	height: 18px;
	margin-right: 0px;
	margin-bottom: -3px;
}
</style>

<style>
.v-data-table__wrapper > table > tbody > tr:hover {
	background: inherit !important;
}

.action-row {
	width: 100%;
	padding: 16px;
}

.ticket-row {
	display: flex;
	align-items: center;
	gap: 10px;
}
</style>
