import Vue from 'vue';
import VueRouter from 'vue-router';
import endpoints from '@/router/routes.js';
import Home from '@/views/home/HomePage.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
	{
		path: endpoints.HOME,
		name: 'HomePage',
		component: Home,
	},
	{
		path: endpoints.HELP,
		name: 'HelpPage',
		component: () => import('@/views/help/HelpPage.vue'),
	},
	{
		path: endpoints.STORE,
		name: 'StorePage',
		component: () => import('@/views/store/StorePage.vue'),
	},
	{
		path: endpoints.CHECKOUT,
		name: 'CheckoutPage',
		component: () => import('@/views/checkout/CheckoutPage.vue'),
		meta: { hideHeader: true, hideFooter: true },
	},
	{
		path: endpoints.CONFIRMATION,
		name: 'ConfirmationPage',
		component: () => import('@/views/confirmation/ConfirmationPage.vue'),
		meta: { hideHeader: true, hideFooter: true },
	},
	{
		path: endpoints.CUSTOM_PET,
		name: 'CustomPetPage',
		component: () => import('@/views/custom-pet/CustomPetPage.vue'),
		meta: { userOnly: true, hideHeader: true, hideFooter: true },
		children: [
			{
				path: endpoints.CUSTOM_PET,
				component: () =>
					import('@/views/custom-pet/components/CustomPetTable.vue'),
				meta: { userOnly: true, hideHeader: true, hideFooter: true },
			},
			{
				path: endpoints.CUSTOM_PET_CREATE,
				component: () =>
					import('@/views/custom-pet/components/CustomPetForm.vue'),
				meta: { userOnly: true, hideHeader: true, hideFooter: true },
			},
		],
	},
	{
		path: endpoints.CUSTOMIZE,
		name: 'CustomizePage',
		component: () => import('@/views/customize/CustomizePage.vue'),
		meta: { userOnly: true },
		children: [
			{
				path: ':command',
				component: () => import('@/views/customize/CustomizeEditorPage.vue'),
			},
		],
	},
	{
		path: endpoints.CAPTCHA,
		name: 'CaptchaPage',
		meta: { hideHeader: true, hideFooter: true },
		component: () => import('@/views/captcha/CaptchaPage.vue'),
	},
	{
		path: endpoints.BATTLE_LOG,
		name: 'BattleLogPage',
		meta: { hideHeader: true, hideFooter: true },
		component: () => import('@/views/battle-logs/BattleLogPage.vue'),
	},
	{
		path: endpoints.REPORT,
		name: 'ReportPage',
		meta: { hideHeader: true, hideFooter: true },
		component: () => import('@/views/report/ReportPage.vue'),
	},
	{
		path: endpoints.EMOTES,
		name: 'EmotesPage',
		component: () => import('@/views/emotes/EmotesPage.vue'),
		meta: { userOnly: true },
	},
	{
		path: endpoints.STATUS,
		name: 'StatusPage',
		meta: { hideHeader: true, hideFooter: true },
		component: () => import('@/views/status/StatusPage.vue'),
	},
	{
		path: endpoints.ADMIN,
		name: 'AdminPage',
		component: () => import('@/views/admin/AdminPage.vue'),
		meta: { adminOnly: true },
		children: [
			{
				path: endpoints.ADMIN_TRANSACTION,
				component: () => import('@/views/admin/AdminTransactionPage.vue'),
			},
			{
				path: endpoints.ADMIN_TRANSACTION_LOGS,
				component: () => import('@/views/admin/AdminTransactionLogPage.vue'),
			},
			{
				path: endpoints.ADMIN_PENDING,
				component: () => import('@/views/admin/AdminPendingPage.vue'),
			},
			{
				path: `${endpoints.ADMIN_CUSTOMIZED}/:command`,
				component: () => import('@/views/admin/AdminCustomizedPage.vue'),
			},
			{
				path: endpoints.ADMIN_REPORT_USER,
				component: () => import('@/views/admin/AdminUserReportPage.vue'),
			},
			{
				path: endpoints.ADMIN_REPORT_GUILD,
				component: () => import('@/views/admin/AdminGuildReportPage.vue'),
			},
			{
				path: endpoints.ADMIN_REPORT_APPEAL,
				component: () => import('@/views/admin/AdminAppealReportPage.vue'),
			},
			{
				path: endpoints.ADMIN_PETS_EDIT,
				component: () => import('@/views/admin/AdminPetsPage.vue'),
			},
			{
				path: endpoints.ADMIN_PETS_APPROVE,
				component: () => import('@/views/admin/AdminPetsApprovePage.vue'),
			},
		],
	},
	{
		path: endpoints.TERMS_OF_SERVICE,
		name: 'TermsOfServicePage',
		component: () => import('@/views/TermsOfServicePage.vue'),
	},
	{
		path: endpoints.PRIVACY_POLICY,
		name: 'PrivacyPolicyPage',
		component: () => import('@/views/PrivacyPolicyPage.vue'),
	},
	{
		path: endpoints.RETURN_POLICY,
		name: 'ReturnPolicyPage',
		component: () => import('@/views/ReturnPolicyPage.vue'),
	},
	{
		path: endpoints.DISCLAIMER,
		name: 'DisclaimerPage',
		component: () => import('@/views/DisclaimerPage.vue'),
	},
	{
		path: '*',
		name: 'PageNotFound',
		component: () => import('@/views/error/PageNotFound.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((record) => record.meta.adminOnly)) {
		const user = await store.dispatch('getUser');
		if (!user?.admin) {
			return next('/');
		}
	}

	next();
});

/*
alterRoutes(routes);
function alterRoutes(routes) {
	const captchaRoute = routes.find((route) => route.path === endpoints.CAPTCHA);
	const path = captchaRoute.path;
	for (let i = 1; i < path.length; i++) {
		const newPath = path.slice(0, i) + '\u200B' + path.slice(i);
		routes.push({
			...captchaRoute,
			path: newPath,
		});
	}
}
*/

export default router;
