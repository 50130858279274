<template>
	<div>
		<v-text-field
			clearable
			label="Discord ID"
			outlined
			@blur="fetchTransactions"
		>
		</v-text-field>
		<AdminTransactionInfo v-if="info" :info="info" />
		<v-data-table
			:headers="headers"
			:items="filteredTransaction"
			class="elevation-1"
			mobile-breakpoint="400"
			:loading="fetching"
			loading-text="Fetching users..."
			:item-class="rowBackground"
		>
			<template v-slot:item.username="{ item }">
				<div class="user-info">
					<v-avatar size="32">
						<v-img :src="item.avatarUrl" alt="avatar" />
					</v-avatar>
					<div class="user-info-text">
						<span class="font-weight-black">
							{{ item.username }}
						</span>
						<span class="text--secondary">
							{{ item.id }}
						</span>
					</div>
				</div>
			</template>
			<template v-slot:item.card="{ item }">
				<div class="card-info">
					<span>
						{{ item.cc_num }}
					</span>
					<span class="text--secondary">
						{{ item.cc_zip }}
					</span>
				</div>
			</template>
			<template v-slot:item.status="{ item }">
				<div class="card-info">
					<span>
						{{ item.status }}
					</span>
					<span class="text--secondary">
						{{ item.reason }}
					</span>
				</div>
			</template>
			<template v-slot:item.action="{ item }">
				<AdminUserReportModal :user="item" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import AdminTransactionInfo from '@/views/admin/components/AdminTransactionInfo';

export default {
	name: 'AdminTransactionInfoPage',
	components: { AdminTransactionInfo },
	data: () => ({
		transactions: [],
		info: {},
		fetching: false,
		headers: [
			{ text: 'Username', value: 'username', sortable: false },
			{ text: 'Date', value: 'date', sortable: false },
			{ text: 'IP', value: 'ip', sortable: false },
			{ text: 'Status', value: 'status', sortable: false },
			{ text: 'Total', value: 'total', sortable: false },
		],
	}),
	computed: {
		filteredTransaction() {
			return this.transactions.map((transaction) => {
				let totalItems = 0;
				if (transaction.items) {
					for (let key in transaction.items) {
						totalItems += transaction.items[key].count;
					}
				}
				return {
					username:
						transaction.user.username + '#' + transaction.user.discriminator,
					id: transaction.user.discordId,
					name: transaction.cc_name,
					date: new Date(transaction.createdAt),
					avatarUrl: this.getAvatarUrl(transaction.user),
					cc_num: transaction.cc_num,
					cc_zip: transaction.cc_zip,
					ip: transaction.ip,
					status: transaction.status,
					reason: transaction.reason,
					total: transaction.total || totalItems + 'n',
				};
			});
		},
	},
	methods: {
		rowBackground(item) {
			switch (item.status) {
				case 'warn':
					return 'warn-row';
				case 'success':
					return 'success-row';
				case 'attempt':
					return 'attempt-row';
				case 'error':
					return 'error-row';
				default:
					return '';
			}
		},
		getAvatarUrl({ discordId, discriminator, avatar }) {
			if (avatar) {
				return `https://cdn.discordapp.com/avatars/${discordId}/${avatar}.png`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(discriminator) % 5
				}.png`;
			}
		},
		async fetchTransactions(ele) {
			let id = ele.target.value;
			if (!id) return;
			id = id.trim();
			let result;
			if (/^\d{16,}$/.test(id)) {
				result = await this.$store.dispatch(
					'getTransactionLogsFromDiscordId',
					id
				);
			}
			console.log(result);
			this.transactions = result.transaction;
			this.info = result.info;
		},
	},
	async mounted() {
		try {
			this.fetching = true;
			const result = await this.$store.dispatch('getTransactionLogs');
			console.log(result);
			this.transactions = result.transaction;
			this.info = result.info;
		} catch (err) {
			console.error(err);
		} finally {
			this.fetching = false;
		}
	},
};
</script>

<style scoped>
.cards {
	display: flex;
	flex-wrap: wrap;
}

.user-info {
	display: flex;
	align-items: center;
}

.user-info-text {
	padding-left: 5px;
	display: flex;
	flex-direction: column;
}

.card-info {
	display: flex;
	flex-direction: column;
}

::v-deep .success-row {
	background-color: rgba(0, 255, 0, 0.2);
}

::v-deep .error-row {
	background-color: rgba(255, 0, 0, 0.2);
}

::v-deep .warn-row {
	background-color: rgba(0, 0, 255, 0.2);
}

::v-deep .attempt-row {
	background-color: rgba(255, 255, 0, 0.3);
}
</style>
