<template>
	<v-card color="background-dark" flat :class="cardClass">
		<v-btn
			v-if="isMobile && !overrideForm"
			icon
			class="drawer-button"
			@click="showDrawer = !showDrawer"
		>
			<v-icon>mdi-menu</v-icon>
		</v-btn>
		<div v-if="overrideForm" class="card-info">{{ cardInfo }}</div>
		<discord-messages>
			<discord-message
				author="OwO"
				:bot="true"
				avatar="https://i.imgur.com/UOdD5Yw.png"
			>
				<discord-embed
					v-if="form.isEmbed"
					slot="embeds"
					:color="color"
					:title="title"
					:thumbnail="form.sideImg"
					:image="form.bottomImg || form.defaultBottomImg"
					:author-name="author"
					:author-image="avatarUrl"
				>
					<div class="body">
						<div
							v-for="(text, index) in texts"
							:key="index"
							:style="
								(text.newline ? 'flex-basis: 100%; height: 0px; ' : '') +
								(text.markdownBlock ? 'flex-basis: 100%;' : '')
							"
						>
							<template v-if="text.word">{{ text.text }}</template>
							<div v-else-if="text.markdownBlock" class="markdown-block">
								{{ text.text }}
							</div>
							<div v-else-if="text.markdownInline" class="markdown-inline">
								{{ text.text }}
							</div>
							<template v-else-if="text.space">{{ '&nbsp;' }}</template>
							<v-img
								v-else-if="text.emoji"
								class="emoji"
								:src="text.url"
								max-width="18"
								max-height="18"
							/>
						</div>
					</div>
					<span slot="footer">{{ footer }}</span>
					<embed-fields
						v-if="formattedFields && formattedFields.length"
						slot="fields"
					>
						<embed-field
							v-for="(field, index) in formattedFields"
							:key="index"
							:inline="true"
							:title="field.title"
							style="width: 50%"
						>
							<div class="body">
								<div
									v-for="(text, index) in field.content"
									:key="index"
									:style="
										(text.newline ? 'flex-basis: 100%; height: 0px; ' : '') +
										(text.markdownBlock ? 'flex-basis: 100%;' : '')
									"
								>
									<template v-if="text.word">{{ text.text }}</template>
									<div v-if="text.markdownBlock" class="markdown-block">
										{{ text.text }}
									</div>
									<div v-if="text.markdownInline" class="markdown-inline">
										{{ text.text }}
									</div>
									<template v-else-if="text.space">{{ '&nbsp;' }}</template>
									<v-img
										v-else-if="text.emoji"
										class="emoji"
										:src="text.url"
										max-width="18"
										max-height="18"
									/>
								</div>
							</div>
						</embed-field>
					</embed-fields>
				</discord-embed>
				<div class="body" v-else>
					<div
						v-for="(text, index) in texts"
						:key="index"
						:style="
							(text.newline ? 'flex-basis: 100%; height: 0px; ' : '') +
							(text.markdownBlock ? 'flex-basis: 100%;' : '')
						"
					>
						<template v-if="text.word">{{ text.text }}</template>
						<div v-if="text.markdownBlock" class="markdown-block">
							{{ text.text }}
						</div>
						<div v-if="text.markdownInline" class="markdown-inline">
							{{ text.text }}
						</div>
						<template v-else-if="text.space">{{ '&nbsp;' }}</template>
						<v-img
							v-else-if="text.emoji"
							class="emoji"
							:src="text.url"
							max-width="18"
							max-height="18"
						/>
					</div>
				</div>
			</discord-message>
		</discord-messages>
	</v-card>
</template>

<script>
import CustomizeMixin from '@/views/customize/mixin/CustomizeMixin.js';
import { replaceText } from '@/views/customize/utils/CustomizeUtil.js';

export default {
	mixins: [CustomizeMixin],
	name: 'CustomizeSidebar',
	props: {
		overrideForm: {
			type: Object,
			required: false,
		},
	},
	computed: {
		cardInfo() {
			let result = '';
			if (this.form.user) {
				result += this.form.user.username + '#' + this.form.user.discriminator;
			} else {
				result += 'Username not saved';
			}
			result += ' - ' + this.form.id;
			result += ' - ' + new Date(this.form.updated_at).toLocaleString();
			return result;
		},
		cardClass() {
			const classes = { scroll: true };
			if (this.overrideForm) {
				classes['card-minimal'] = true;
			} else if (this.isMobile) {
				classes['card-mobile'] = true;
			} else {
				classes['card'] = true;
			}
			return classes;
		},
		form() {
			return this.overrideForm || this.$store.getters.selectedForm || {};
		},
		color() {
			let hex;
			if (!this.form.color) hex = '#FFFFFF';
			else hex = '#' + this.form.color.toString(16);
			return hex;
		},
		texts() {
			if (!this.form?.text) return;
			const test = this.splitMarkdown(
				replaceText(
					this.$route.params.command || this.form.command,
					this.form,
					this.form.text
				)
			);
			return test;
		},
		formattedFields() {
			if (!this.form.fields) return;
			const formatted = [];
			this.form.fields.forEach((f) => {
				formatted.push({
					title: f.title,
					content: this.splitMarkdown(
						replaceText(
							this.$route.params.command || this.form.command,
							this.form,
							f.content
						)
					),
				});
			});
			return formatted;
		},
		avatarUrl() {
			return this.form.showAvatar ? this.$store.getters.user.avatarUrl : '';
		},
		title() {
			if (!this.form?.title) return;
			return replaceText(
				this.$route.params.command || this.form.command,
				this.form,
				this.form.title
			);
		},
		author() {
			if (!this.form?.author) return;
			return replaceText(
				this.$route.params.command || this.form.command,
				this.form,
				this.form.author
			);
		},
		footer() {
			if (!this.form?.footer) return;
			return replaceText(
				this.$route.params.command || this.form.command,
				this.form,
				this.form.footer
			);
		},
	},
	methods: {
		splitMarkdown(body) {
			const regex = /```[^`]+```/gi;
			const regex2 = /`[^`]+`/gi;
			if (regex.test(body)) {
				let textsArray = [];
				const markdown = body.match(regex);
				const texts = body.split(regex);
				for (let i = 0; i < texts.length; i++) {
					if (texts[i]) {
						textsArray = textsArray.concat(this.splitMarkdown(texts[i]));
					}
					if (i < markdown.length) {
						textsArray.push({
							text: markdown[i]
								.replace(/^```\w*/gi, '')
								.replace(/```$/gi, '')
								.replace(/^\n*/, ''),
							markdownBlock: true,
						});
					}
				}
				return textsArray;
			} else if (regex2.test(body)) {
				let textsArray = [];
				const markdown = body.match(regex2);
				const texts = body.split(regex2);
				for (let i = 0; i < texts.length; i++) {
					if (texts[i]) {
						textsArray = textsArray.concat(this.splitMarkdown(texts[i]));
					}
					if (i < markdown.length) {
						markdown[i]
							.replace(/^`/gi, '')
							.replace(/`$/gi, '')
							.split('\n')
							.forEach((text) => {
								textsArray.push({ text, markdownInline: true });
								textsArray.push({ newline: true });
							});
						textsArray.pop();
					}
				}
				return textsArray;
			} else {
				return this.splitText(body);
			}
		},
		splitText(body) {
			let texts = [];
			body.split('\n').forEach((text) => {
				text.split(' ').forEach((word) => {
					const emoji = this.checkEmoji(word);
					if (emoji) {
						texts = texts.concat(emoji);
					} else {
						texts.push({ text: word, word: true });
					}
					texts.push({ space: true });
				});
				texts.pop();
				texts.push({ newline: true });
			});
			texts.pop();
			return texts;
		},
		checkEmoji(text) {
			if (/<a?:[\w_]+:\d+>/i.test(text)) {
				const textsArray = [];
				const emojis = text.match(/<a?:[\w_]+:\d+>/gi);
				const texts = text.split(/<a?:[\w_]+:\d+>/gi);
				for (let i = 0; i < texts.length; i++) {
					if (texts[i]) {
						textsArray.push({
							text: texts[i],
							word: true,
						});
					}
					if (i < emojis.length) {
						const id = emojis[i].match(/:\d+>/i)[0].match(/\d+/i)[0];
						const isGif = !!emojis[i].match('<a:')?.length;
						textsArray.push({
							url: `https://cdn.discordapp.com/emojis/${id}.${
								isGif ? 'gif' : 'png'
							}`,
							emoji: true,
						});
					}
				}
				return textsArray;
			}
			return false;
		},
	},
};
</script>

<style scoped>
.card {
	min-width: 620px;
	max-width: 620px;
	min-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-mobile {
	width: 100%;
	height: max-content;
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 10px;
	overflow-x: auto;
}

.card-minimal {
	width: 100%;
	height: max-content;
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 10px;
	overflow-x: auto;
}

.body {
	white-space: pre-wrap;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
}

.emoji {
	width: 18px;
}

.markdown-block {
	flex-basis: 100%;
	width: 100%;
	height: 100%;
	background-color: #2f3136;
	border: 1px solid #202225;
	padding: 7px;
	font-family: Consolas, Andale Mono WT, Andale Mono, Lucida Console,
		Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono,
		Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace;
	font-size: 0.875rem;
	line-height: 1.125rem;
	text-indent: 0;
	white-space: pre-wrap;
}

.markdown-inline {
	font-family: Consolas, Andale Mono WT, Andale Mono, Lucida Console,
		Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono,
		Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace;
	padding: 2.73px;
	background-color: rgb(47, 49, 54);
}

.drawer-button {
	position: absolute;
	top: 2px;
	left: 2px;
}

.card-info {
	position: absolute;
	top: 18px;
}
</style>
