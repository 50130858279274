<template>
	<v-card v-ripple="{ class: `red--text` }" @click="like">
		<v-img
			ref="img"
			:src="emoteUrl"
			:width="width"
			height="200"
			:gradient="gradient"
			class="white--text align-end emote-image"
			contain
			@load="loaded"
		>
			<v-skeleton-loader
				v-if="!imgLoaded"
				width="100%"
				height="100%"
				type="card, image"
				loading
			></v-skeleton-loader>
			<v-card-title class="pl-2 pb-3">
				<span ref="category">{{ emote.category }}</span>
			</v-card-title>
			<v-card-subtitle class="pb-1 pl-2">
				<span ref="username">{{ emote.by.username }}</span>
			</v-card-subtitle>
			<div class="like-text" :style="offsetTop">
				<span ref="likeButton">
					<v-icon v-if="!liked" class="mr-1" color="red" small>
						mdi-heart-outline
					</v-icon>
					<v-icon v-else class="mr-1" color="red" small loading>
						mdi-heart
					</v-icon>
					<span class="text-subtitle-2 white--text">{{ emote.likes }}</span>
				</span>
			</div>
			<div v-if="isAdmin" class="admin-buttons">
				<v-btn icon color="red" @click="deleteEmote"
					><v-icon>mdi-delete</v-icon></v-btn
				>
				<v-btn icon color="primary" @click="approveEmote"
					><v-icon>mdi-thumb-up</v-icon></v-btn
				>
			</div>
		</v-img>
	</v-card>
</template>

<script>
export default {
	name: 'EmoteCard',
	props: {
		emote: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		imgLoaded: false,
		width: null,
		offsetTop: '',
		liked: false,
		loading: false,
	}),
	computed: {
		isAdmin() {
			return this.$store.getters.user.admin;
		},
		emoteUrl() {
			return `${process.env.VUE_APP_CDN}/cdn/emote/pending/${this.emote._id}.${this.emote.image.ext}`;
		},
		gradient() {
			return this.isAdmin
				? 'to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%'
				: 'to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%';
		},
	},
	methods: {
		loaded() {
			// Set image width
			const img = this.$refs.img;
			const ratio = img.computedAspectRatio;
			const height = img.height;
			const width = ratio * height;
			this.width = width;
			this.imgLoaded = true;

			// Set like button position
			this.$nextTick(() => {
				const buttonWidth = this.$refs.likeButton.offsetWidth + 9;

				const usernameWidth =
					this.$refs.username.offsetWidth + this.$refs.username.offsetLeft;
				let spaceLeft = width - usernameWidth;

				if (spaceLeft > buttonWidth) {
					this.offsetTop = `top: ${height - 28}px;`;
				} else {
					const categoryWidth =
						this.$refs.category.offsetWidth + this.$refs.category.offsetLeft;
					spaceLeft = width - categoryWidth;

					if (spaceLeft > buttonWidth) {
						this.offsetTop = `top: ${this.$refs.category.offsetTop + 5}px;`;
					} else {
						this.offsetTop = `top: ${this.$refs.category.offsetTop - 16}px;`;
					}
				}
			});

			if (!ratio) {
				setTimeout(this.loaded, 1);
			}
		},
		async like() {
			if (this.loading) return;
			this.loading = true;
			try {
				if (this.liked) {
					await this.$store.dispatch('dislikeEmote', this.emote._id);
					this.liked = false;
					// eslint-disable-next-line vue/no-mutating-props
					this.emote.likes--;
				} else {
					await this.$store.dispatch('likeEmote', this.emote._id);
					this.liked = true;
					// eslint-disable-next-line vue/no-mutating-props
					this.emote.likes++;
				}
			} catch (err) {
				const opt = {
					text:
						'We failed to ' +
						(this.liked ? 'dislike' : 'like') +
						' this emote! Please try again later.',
					width: 500,
					imgUrl: 'owo-cry.png',
					buttons: [
						{
							text: 'Aww',
							color: 'primary',
						},
					],
				};
				this.$modal(opt).showError();
			} finally {
				this.loading = false;
			}
		},
		async banEmote() {
			const opt = {
				text: `Are you sure you want to ban ${this.emote.by.username} from the website?`,
				width: 400,
				bottomImgUrl: this.emoteUrl,
				bottomText: `Author: <b>${this.emote.by.username}</b><br>Category: <b>${this.emote.category}</b>`,
				buttons: [
					{
						text: 'Ban',
						color: 'error',
					},
				],
			};
			const yes = await this.$modal(opt).showError();
			if (!yes) return;

			try {
				await this.$store.dispatch('banEmote', this.emote._id);
			} catch (err) {
				const opt = {
					text: 'There was an error banning this user/emote! Please try again later.',
					width: 500,
					imgUrl: 'owo-cry.png',
					buttons: [
						{
							text: 'Ok...',
							color: 'primary',
						},
					],
				};
				this.$modal(opt).showError();
			}
		},
		async deleteEmote(e) {
			e.stopPropagation();
			const opt = {
				text: `Are you sure you want to delete this emote?`,
				width: 400,
				bottomImgUrl: this.emoteUrl,
				bottomText: `Author: <b>${this.emote.by.username}</b><br>Category: <b>${this.emote.category}</b>`,
				buttons: [
					{
						text: 'Delete',
						color: 'error',
						returns: 'delete',
					},
					{
						text: 'Ban',
						color: 'error',
						outlined: true,
						returns: 'ban',
					},
				],
			};
			const result = await this.$modal(opt).showWarn();
			if (!result) return;
			else if (result === 'ban') return this.banEmote();

			try {
				await this.$store.dispatch('deleteEmote', this.emote._id);
			} catch (err) {
				const opt = {
					text: 'There was an error deleting this emote! Please try again later.',
					width: 500,
					imgUrl: 'owo-cry.png',
					buttons: [
						{
							text: 'Ok...',
							color: 'primary',
						},
					],
				};
				this.$modal(opt).showError();
			}
		},
		async approveEmote(e) {
			e.stopPropagation();
			const opt = {
				text: `Are you sure you want to approve this emote?`,
				width: 400,
				bottomImgUrl: this.emoteUrl,
				bottomText: `Author: <b>${this.emote.by.username}</b><br>Category: <b>${this.emote.category}</b>`,
				buttons: [
					{
						text: 'Approve',
						color: 'primary',
					},
				],
			};
			const yes = await this.$modal(opt).showInfo();
			if (!yes) return;

			try {
				await this.$store.dispatch('approveEmote', this.emote._id);
			} catch (err) {
				const opt = {
					text: 'There was an error approving this emote! Please try again later.',
					width: 500,
					imgUrl: 'owo-cry.png',
					buttons: [
						{
							text: 'Ok...',
							color: 'primary',
						},
					],
				};
				this.$modal(opt).showError();
			}
		},
	},
	mounted() {
		if (this.emote.likedBy) {
			this.liked = !!this.emote.likedBy[this.$store.getters.user.discordId];
		}
	},
};
</script>

<style scoped>
.emote-image {
	position: relative;
}
.like-text {
	position: absolute;
	right: 10px;
}
.admin-buttons {
	position: absolute;
	top: 6px;
	left: 6px;
	width: calc(100% - 12px);
	display: flex;
	justify-content: space-between;
}
</style>
