<template>
	<v-card :color="isMobile ? null : 'background'">
		<v-app-bar-nav-icon
			v-if="isMobile"
			@click.stop="drawer = !drawer"
		></v-app-bar-nav-icon>
		<v-navigation-drawer
			color="grey darken-4"
			class="navigation-drawer"
			:permanent="!isMobile"
			v-model="drawer"
			:temporary="isMobile"
			:height="isMobile ? '100%' : null"
			:app="isMobile"
		>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-h6"> Admin Panel </v-list-item-title>
					<v-list-item-subtitle> ban. </v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<v-list dense nav>
				<span v-for="item in items" :key="item.to">
					<v-list-item v-if="!item.subcategories" :to="item.to">
						<v-list-item-icon>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ item.text }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-group v-else :prepend-icon="item.icon">
						<template v-slot:activator>
							<v-list-item-title>{{ item.text }}</v-list-item-title>
						</template>
						<v-list-item
							v-for="subcategory in item.subcategories"
							:key="subcategory.to"
							:to="subcategory.to"
						>
							<v-list-item-icon> </v-list-item-icon>
							<v-list-item-title>{{ subcategory.text }}</v-list-item-title>
							<v-list-item-icon>
								<v-icon>{{ subcategory.icon }}</v-icon>
							</v-list-item-icon>
						</v-list-item>
					</v-list-group>
				</span>
			</v-list>
		</v-navigation-drawer>
	</v-card>
</template>

<script>
import endpoints from '@/router/routes.js';

export default {
	name: 'AdminNavigator',
	data: () => ({
		drawer: false,
		tab: null,
		items: [
			{
				text: 'Transactions',
				icon: 'mdi-cash-multiple',
				to: endpoints.ADMIN_TRANSACTION,
			},
			{
				text: 'Items Pending',
				icon: 'mdi-email-alert',
				to: endpoints.ADMIN_PENDING,
			},
			{
				text: 'Customize',
				icon: 'mdi-palette',
				subcategories: [
					{
						text: 'Hunt',
						icon: 'mdi-leaf',
						to: `${endpoints.ADMIN_CUSTOMIZED}/hunt`,
					},
					{
						text: 'Battle',
						icon: 'mdi-sword-cross',
						to: `${endpoints.ADMIN_CUSTOMIZED}/battle`,
					},
					{
						text: 'Cowoncy',
						icon: 'mdi-palette',
						to: `${endpoints.ADMIN_CUSTOMIZED}/cowoncy`,
					},
					{
						text: 'Give',
						icon: 'mdi-palette',
						to: `${endpoints.ADMIN_CUSTOMIZED}/give`,
					},
					{
						text: 'Pray',
						icon: 'mdi-palette',
						to: `${endpoints.ADMIN_CUSTOMIZED}/pray`,
					},
					{
						text: 'Weapon',
						icon: 'mdi-palette',
						to: `${endpoints.ADMIN_CUSTOMIZED}/weapon`,
					},
					{
						text: 'Cookie',
						icon: 'mdi-palette',
						to: `${endpoints.ADMIN_CUSTOMIZED}/cookie`,
					},
					{
						text: 'Daily',
						icon: 'mdi-palette',
						to: `${endpoints.ADMIN_CUSTOMIZED}/daily`,
					},
					{
						text: 'Inventory',
						icon: 'mdi-palette',
						to: `${endpoints.ADMIN_CUSTOMIZED}/inventory`,
					},
				],
			},
			{
				text: 'Reports',
				icon: 'mdi-comment-alert',
				subcategories: [
					{
						text: 'User Reports',
						icon: 'mdi-account',
						to: `${endpoints.ADMIN_REPORT_USER}`,
					},
					{
						text: 'Guild Reports',
						icon: 'mdi-account-group',
						to: `${endpoints.ADMIN_REPORT_GUILD}`,
					},
					{
						text: 'Appeals',
						icon: 'mdi-message-check',
						to: `${endpoints.ADMIN_REPORT_APPEAL}`,
					},
				],
			},
			{
				text: 'Pets',
				icon: 'mdi-paw',
				subcategories: [
					{
						text: 'Edit',
						icon: 'mdi-pencil',
						to: `${endpoints.ADMIN_PETS_EDIT}`,
					},
					{
						text: 'Approve',
						icon: 'mdi-check-bold',
						to: `${endpoints.ADMIN_PETS_APPROVE}`,
					},
				],
			},
		],
	}),
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 700;
		},
	},
	mounted() {
		if (
			['184587051943985152', '158436239488319488'].includes(
				this.$store.getters.user?.id
			)
		) {
			this.items.splice(1, 0, {
				text: 'Transaction Logs',
				icon: 'mdi-account-cash',
				to: endpoints.ADMIN_TRANSACTION_LOGS,
			});
		}
	},
};
</script>

<style scoped>
.navigation-drawer {
	height: 100%;
}
</style>
