<template>
	<v-navigation-drawer
		v-model="showDrawer"
		:permanent="isLarge"
		:absolute="!isLarge"
		class="customize-sidebar"
	>
		<v-list dense>
			<v-list-item
				v-for="command in commands"
				:key="command.link"
				:to="`/user/customize/${command.link}`"
			>
				<v-list-item-icon>
					<v-img :src="command.icon" contain width="20" height="20"></v-img>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ command.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import CustomizeMixin from '@/views/customize/mixin/CustomizeMixin.js';

export default {
	mixins: [CustomizeMixin],
	name: 'CustomizeSidebar',
	computed: {
		commands() {
			return this.$store.getters.commands.filter((command) => command.enabled);
		},
		customizeLoaded() {
			return this.$store.getters.customizeLoaded;
		},
	},
	watch: {
		customizeLoaded(to) {
			if (to && this.commands.length && !this.$route.params.command) {
				this.$router.push(`/user/customize/${this.commands[0].link}`);
			}
		},
	},
};
</script>

<style scoped>
.customize-page {
	width: 100%;
	height: 100%;
	display: flex;
}

.customize-sidebar {
	min-width: 200px;
}
</style>
