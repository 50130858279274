<template>
	<div>
		<v-card>
			<v-card-text>
				<div class="emoji-guild-row text-h6">
					<v-btn color="secondary" @click="showGuilds = !showGuilds"
						>Info</v-btn
					>
					<div>Gifs: {{ availableGifs }}</div>
					<div>Images: {{ availableImages }}</div>
					<div class="spacer"></div>
					<v-text-field
						class="guild-field"
						v-model="search"
						label="Add New Guild"
						outlined
					></v-text-field>
					<v-btn color="primary" @click="addGuild">Add</v-btn>
				</div>
			</v-card-text>

			<v-data-table
				v-show="showGuilds"
				:headers="headers"
				:items="guilds"
				hide-default-footer
				hide-default-header
			>
				<template v-slot:item.space="{ item }">
					{{ item.gifSpace }} | {{ item.imageSpace }}
				</template>
				<template v-slot:item.action="{ item }">
					<v-btn color="error" text @click="deleteGuild(item)">
						<v-icon>mdi-trash-can-outline</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
export default {
	name: 'AdminEmojiGuilds',
	components: {},
	data: () => ({
		availableGifs: 10,
		availableImages: 12,
		search: null,
		showGuilds: false,
		headers: [
			{ value: '_id', sortable: false },
			{ value: 'space', sortable: false },
			{ value: 'action', sortable: false },
		],
		guilds: [],
	}),
	methods: {
		async addGuild() {
			let id = this.search;
			if (!id) {
				const opt = {
					text: 'Please add a guild id',
					imgUrl: 'owo-cry.png',
				};
				this.$modal(opt).showError();
				return;
			}
			id = id.trim();
			if (!/^\d+$/.test(id)) {
				const opt = {
					text: 'Invalid Guild id',
					imgUrl: 'owo-cry.png',
				};
				this.$modal(opt).showError();
				return;
			}
			try {
				await this.$store.dispatch('addEmojiServer', id);
			} catch (err) {
				console.error(err);
				const opt = {
					text: 'Failed to add guild',
					imgUrl: 'owo-cry.png',
				};
				this.$modal(opt).showError();
			}
			this.search = null;
			const opt = {
				text: 'Successfully added guild id',
			};
			this.$modal(opt).showInfo();
		},
		async getGuilds() {
			const res = await this.$store.dispatch('getEmojiServers');
			this.availableGifs = res.gifSpace;
			this.availableImages = res.imageSpace;
			this.guilds = res.guilds;
		},
	},
	mounted() {
		this.getGuilds();
	},
};
</script>

<style scoped>
.emoji-guild-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.guild-field {
	margin-bottom: -28px;
	max-width: 500px;
}

.spacer {
	flex-growth: 1;
}
</style>
