import axios from 'axios';
import { setDefaultFields } from '@/views/customize/utils/CustomizeUtil.js';

const actions = {
	getTransactionFromCaptureId: async (vue, captureId) => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/admin/transaction/capture-id/${captureId}`,
			{ withCredentials: true }
		);
		return res.data;
	},
	getTransactionFromDiscordId: async (vue, discordId) => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/admin/transaction/discord-id/${discordId}`,
			{ withCredentials: true }
		);
		return res.data;
	},
	getTransactions: async () => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/admin/transaction/recent`,
			{ withCredentials: true }
		);
		return res.data;
	},
	getTransactionLogs: async () => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/admin/transaction-logs/recent`,
			{ withCredentials: true }
		);
		return res.data;
	},
	getCustomizedCommands: async (vue, { page, command }) => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/admin/customize/${command}?page=${page}&limit=10`,
			{ withCredentials: true }
		);
		res.data.forEach((row) => {
			setDefaultFields(row.command, row.type, row);
		});
		return res.data;
	},
	getPendingTransactions: async () => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/admin/transaction/pending`,
			{ withCredentials: true }
		);
		return res.data;
	},
	checkCloverTransaction: async (_vue, sessionID) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/clover/check`,
			{ sessionID },
			{ withCredentials: true }
		);
	},
	ignoreCloverTransaction: async (_vue, sessionID) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/clover/ignore`,
			{ sessionID },
			{ withCredentials: true }
		);
	},
	banUser: async (vue, { email, userId, giftId, ip }) => {
		console.log('Banned: ', userId);
		console.log('Banned: ', giftId);
		console.log('Receiver: ', giftId || userId);
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/ban`,
			{ email, userId, giftId, ip },
			{ withCredentials: true }
		);
	},
	resetUserCowoncyWithReason: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/reset-cowoncy`,
			{ userId: id, reason },
			{ withCredentials: true }
		);
	},
	banUserWithReason: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/ban-user`,
			{ userId: id, reason },
			{ withCredentials: true }
		);
	},
	banGuildWithReason: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/ban-guild`,
			{ guildId: id, reason },
			{ withCredentials: true }
		);
	},
	banReportWithReason: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/ban-report`,
			{ userId: id, reason },
			{ withCredentials: true }
		);
	},
	warnUser: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/warn-user`,
			{ userId: id, reason },
			{ withCredentials: true }
		);
	},
	messageUser: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/message-user`,
			{ userId: id, reason },
			{ withCredentials: true }
		);
	},
	commentUser: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/comment-user`,
			{ userId: id, reason },
			{ withCredentials: true }
		);
	},
	commentGuild: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/comment-guild`,
			{ guildId: id, reason },
			{ withCredentials: true }
		);
	},
	ignoreAllUserReports: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/ignore-user-reports`,
			{ userId: id, reason },
			{ withCredentials: true }
		);
	},
	ignoreAllGuildReports: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/ignore-guild-reports`,
			{ guildId: id, reason },
			{ withCredentials: true }
		);
	},
	ignoreComment: async (vue, { id, reason, reportId }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/comment-ignore`,
			{ userId: id, reason, reportId },
			{ withCredentials: true }
		);
	},
	requestInfo: async (vue, { id, reason, reportId }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/comment-request`,
			{ userId: id, reason, reportId },
			{ withCredentials: true }
		);
	},
	warnComment: async (vue, { id, reason, reportId }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/comment-warn`,
			{ userId: id, reason, reportId },
			{ withCredentials: true }
		);
	},
	messageComment: async (vue, { id, reason, reportId }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/comment-message`,
			{ userId: id, reason, reportId },
			{ withCredentials: true }
		);
	},
	leaveCommentComment: async (vue, { id, reason, reportId }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/comment-comment`,
			{ userId: id, reason, reportId },
			{ withCredentials: true }
		);
	},
	liftUser: async (vue, { id, reason, reportId }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/lift-user`,
			{ userId: id, reason, reportId },
			{ withCredentials: true }
		);
	},
	shopUser: async (vue, { id, reason, reportId }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/shop-user`,
			{ userId: id, reason, reportId },
			{ withCredentials: true }
		);
	},
	getAdminPet: async (vue, petName) => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/admin/pet/${petName}`,
			{ withCredentials: true }
		);
		return res.data;
	},
	editAdminPet: async (vue, pet) => {
		return axios.post(`${process.env.VUE_APP_BACKEND}/api/admin/pet`, pet, {
			withCredentials: true,
		});
	},
	getAdminPendingPets: async (
		vue,
		{ page, limit, decline, success, pending }
	) => {
		let res = await axios.get(
			`${
				process.env.VUE_APP_BACKEND
			}/api/admin/custom-pet?page=${page}&limit=${limit}&decline=${!!decline}&success=${!!success}&pending=${!!pending}`,
			{ withCredentials: true }
		);
		return res.data;
	},
	approveAdminPendingPets: async (vue, id) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/custom-pet`,
			{ id },
			{ withCredentials: true }
		);
	},
	declineAdminPendingPets: async (vue, { id, reason }) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/decline-custom-pet`,
			{ id, reason },
			{ withCredentials: true }
		);
	},
	getEmojiServers: async () => {
		let res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/admin/emoji-guild`,
			{ withCredentials: true }
		);
		return res.data;
	},
	addEmojiServer: async (vue, id) => {
		return axios.post(
			`${process.env.VUE_APP_BACKEND}/api/admin/add-emoji-guild`,
			{ id },
			{ withCredentials: true }
		);
	},
};

export default { actions };
