<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="pets"
			:loading="loading"
			:footer-props="footerProps"
			:options.sync="options"
			sort-by="updated"
			sort-desc
			:server-items-length="total"
			:item-class="rowBackground"
		>
			<template v-slot:top>
				<div class="filter-top">
					<v-checkbox
						v-model="pending"
						label="Pending"
						color="yellow"
						@click="fetchPets"
						hide-details
					></v-checkbox>
					<v-checkbox
						v-model="decline"
						label="Declined"
						color="red"
						@click="fetchPets"
						hide-details
					></v-checkbox>
					<v-checkbox
						v-model="success"
						label="Approved"
						color="green"
						@click="fetchPets"
						hide-details
					></v-checkbox>
				</div>
			</template>
			<template v-slot:item.image="{ item }">
				<v-img :src="item.imageUrl" width="50" contain></v-img>
				<div v-html="item.title"></div>
			</template>
			<template v-slot:item.createdAt="{ item }">
				{{ new Date(item.createdAt).toLocaleString() }}
			</template>
			<template v-slot:item.by="{ item }">
				<div class="username-row">
					{{ item.by.globalName }}
					<span class="discord-id">{{ item.by.username }}</span>
					<span class="discord-id">{{ item.by.discordId }}</span>
				</div>
			</template>
			<template v-slot:item.action="{ item }">
				<v-btn color="primary" text>
					<AdminPetsApproveModal :pet="item" @refresh="fetchPets" />
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import AdminPetsApproveModal from '@/views/admin/components/AdminPetsApproveModal';

export default {
	name: 'AdminPetsApproveTable',
	components: { AdminPetsApproveModal },
	props: {
		loading: {
			type: Boolean,
			required: true,
		},
		pets: {
			type: Array,
			required: true,
		},
		total: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		options: {
			page: 1,
			itemsPerPage: 25,
		},
		footerProps: {
			'items-per-page-options': [25, 50, 100],
		},
		headers: [
			{ text: '', value: 'image', sortable: false },
			{ text: 'Pet Name', value: 'name', sortable: true },
			{ text: 'By', value: 'by', sortable: true },
			{ text: 'Created', value: 'createdAt', sortable: true },
			{ text: '', value: 'action', sortable: false },
		],
		decline: false,
		success: false,
		pending: true,
	}),
	watch: {
		options: {
			handler() {
				this.fetchPets();
			},
			deep: true,
		},
	},
	methods: {
		rowBackground(item) {
			if (item.success && item.decline) {
				return 'warn-row';
			} else if (item.success) {
				return 'success-row';
			} else if (item.decline) {
				return 'error-row';
			} else {
				return 'pending-row';
			}
		},
		fetchPets() {
			this.$emit('fetch', {
				page: this.options.page,
				limit: this.options.itemsPerPage,
				decline: this.decline,
				success: this.success,
				pending: this.pending,
			});
		},
	},
};
</script>

<style scoped>
.discord-id {
	opacity: 0.6;
	font-size: xx-small;
}

.username-row {
	display: flex;
	flex-direction: column;
}

::v-deep .success-row {
	background-color: rgba(0, 255, 0, 0.2);
}

::v-deep .error-row {
	background-color: rgba(255, 0, 0, 0.2);
}

::v-deep .warn-row {
	background-color: rgba(255, 255, 0, 0.4);
}

.filter-top {
	display: flex;
	gap: 10px;
	padding-left: 20px;
}
</style>
