<template>
	<div>
		<v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="primary" v-bind="attrs" v-on="on">
					<v-icon> mdi-eye-outline </v-icon>
				</v-btn>
			</template>
			<v-card color="background">
				<v-toolbar color="primary">
					<v-btn icon dark @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>

					<v-toolbar-title>
						<v-avatar size="32">
							<v-img :src="pet.imageUrl" alt="pet" />
						</v-avatar>
						<b>{{ pet.name }}</b>
					</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
				<div>
					<AdminPetsInfo :pet="pet" @close="closeAndRefresh" />
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import AdminPetsInfo from '@/views/admin/components/AdminPetsInfo';

export default {
	name: 'AdminPetsApprovalModal',
	components: {
		AdminPetsInfo,
	},
	props: {
		pet: {
			type: Object,
		},
	},
	data: () => ({
		dialog: false,
	}),
	methods: {
		async closeAndRefresh() {
			this.dialog = false;
			this.$emit('refresh');
		},
	},
	mounted() {},
};
</script>

<style scoped></style>
