<template>
	<div>
		<v-btn
			v-if="!user || !user.id"
			class="pr-3 pl-3"
			outlined
			:ripple="{ class: 'secondary--text' }"
			@click="login"
		>
			<v-img
				:src="require('@/assets/discord-logo.svg')"
				class="my-3 ml-1"
				contain
				height="20"
				width="20"
			/>
			<span>Login</span>
		</v-btn>
		<v-menu offset-y nudge-bottom="20" v-else>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					depressed
					color="primary"
					@click="toggleDropdown"
					:ripple="{ class: 'secondary--text' }"
					class="user-info pl-3 pr-3"
					height="56"
				>
					<div class="avatar-border">
						<v-avatar size="40">
							<v-img :src="avatarUrl" @error="avatarError" alt="avatar" />
						</v-avatar>
					</div>
					<v-icon class="ml-1 arrow" :class="{ 'arrow-down': dropdown }">
						mdi-chevron-down
					</v-icon>
				</v-btn>
			</template>
			<v-card color="background-dark2">
				<v-list color="background-dark2">
					<v-list-item>
						<v-list-item-title v-text="user.fullName"></v-list-item-title>
					</v-list-item>
					<v-divider class="mt-2"></v-divider>
					<v-list-item-group>
						<v-list-item dense :to="routes.CUSTOMIZE" v-if="hasCustomize">
							<v-list-item-icon>
								<v-icon>mdi-palette</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-text="'Customize'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item dense :to="routes.CUSTOM_PET" v-if="hasCustomPet">
							<v-list-item-icon>
								<v-badge color="secondary" dot :value="customPetTickets">
									<v-icon>mdi-paw</v-icon>
								</v-badge>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-text="'Custom Pet'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<!--
						<v-list-item dense :to="routes.EMOTES" v-if="user.admin">
							<v-list-item-icon>
								<v-icon>mdi-image</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-text="'Emotes'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						-->
						<v-list-item dense :to="routes.BATTLE_LOG">
							<v-list-item-icon>
								<v-icon>mdi-sword-cross</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-text="'Battle Logs'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item dense :to="routes.ADMIN" v-if="user.admin">
							<v-list-item-icon>
								<v-icon>mdi-cog</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-text="'Admin Panel'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item dense :to="routes.REPORT">
							<v-list-item-icon>
								<v-icon>mdi-message-alert</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-text="'Report'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item dense @click="logout">
							<v-list-item-icon>
								<v-icon>mdi-logout</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-text="'Logout'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
import routes from '@/router/routes.js';
export default {
	name: 'DiscordDropdown',
	data: () => ({
		redirect: process.env.VUE_APP_BACKEND + '/api/auth/discord',
		avatarGifError: false,
		avatarPngError: false,
		dropdown: false,
		routes,
	}),
	computed: {
		user() {
			return this.$store.getters.user;
		},
		avatarUrl() {
			let url;
			if (!this.user.avatar) {
				url = `https://cdn.discordapp.com/embed/avatars/${
					parseInt(this.user.discriminator) % 5
				}.png`;
			} else if (!this.avatarGifError) {
				url = this.user.avatarUrlGif;
			} else if (!this.avatarPngError) {
				url = this.user.avatarUrlPng;
			}
			this.$store.dispatch('setAvatarUrl', url);
			return url;
		},
		hasCustomize() {
			return this.user.hasCustomize;
		},
		hasCustomPet() {
			return (
				this.$store.getters.customPetTickets > 0 ||
				this.$store.getters.customPets.length > 0 ||
				this.$store.getters.customPending.length > 0
			);
		},
		customPetTickets() {
			return this.$store.getters.customPetTickets;
		},
	},
	methods: {
		login() {
			this.$store.dispatch('setPreLoginUrl', this.$route.path);
			window.location.href = this.redirect;
		},
		logout() {
			this.$store.dispatch('logout');
			this.$store.dispatch('clearCart');
		},
		toggleDropdown() {
			this.dropdown = !this.dropdown;
		},
		avatarError() {
			if (!this.avatarGifError) {
				this.avatarGifError = true;
			} else if (!this.avatarPngError) {
				this.avatarPngError = true;
			}
		},
	},
	async created() {
		let user;
		let reroute = false;
		try {
			user = await this.$store.dispatch('getUser');
			if (user.captcha?.active) {
				reroute = true;
				this.$router.replace(routes.CAPTCHA);
			}
		} catch (err) {
			if (err.response.status != 401) {
				return console.error(err);
			}
			setTimeout(() => {
				this.$route.matched.forEach((match) => {
					if (match.meta.userOnly) {
						this.$store.dispatch('setPreLoginUrl', this.$route.path);
						window.location.href =
							process.env.VUE_APP_BACKEND + '/api/auth/discord';
						return;
					}
				});
			}, 500);
		}
		if (this.$route.query?.login === '') {
			const newQuery = { ...this.$route.query };
			delete newQuery.login;
			if (!reroute) {
				this.$router.replace({ query: newQuery });
			}
			if (!user) {
				window.location.href = this.redirect;
			}
		}
		await this.$store.dispatch('getCustomPets');
	},
};
</script>

<style scoped>
.user-info {
	display: flex;
	align-items: center;
}

.arrow {
	transition-duration: 1s;
	transition-property: transform;
}

.arrow-down {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
}

.avatar-border {
	display: flex;
	align-items: center;
	border-radius: 50%;
	border: 2px solid white;
	width: 42;
	height: 42;
}

.username {
	padding-bottom: 20px;
	width: 100%;
}
</style>
