<template>
	<div>
		<AdminEmojiGuilds class="emoji-guilds" />
		<AdminPetsApproveTable
			:pets="pets"
			:total="total"
			@fetch="fetchPets"
			:loading="loading"
		/>
	</div>
</template>

<script>
import AdminPetsApproveTable from '@/views/admin/components/AdminPetsApproveTable';
import AdminEmojiGuilds from '@/views/admin/components/AdminEmojiGuilds';

export default {
	name: 'AdminPetsApprovePage',
	components: { AdminPetsApproveTable, AdminEmojiGuilds },
	data: () => ({
		pets: [],
		total: 0,
		loading: false,
	}),
	methods: {
		async fetchPets({ page, limit, decline, success, pending }) {
			this.loading = true;
			try {
				let result = await this.$store.dispatch('getAdminPendingPets', {
					page,
					limit,
					decline,
					success,
					pending,
				});
				this.pets = result.pets;
				this.total = result.total;
				this.pets.forEach((pet) => {
					pet.imageUrl = this.getPetImageUrl(pet);
				});
			} catch (err) {
				console.error(err);
			} finally {
				this.loading = false;
			}
		},
		getPetImageUrl(pet) {
			return `${process.env.VUE_APP_CDN}/cdn/custom-pet/pending/${pet._id}.${pet.image.ext}`;
		},
	},
	mounted() {
		this.fetchPets({ page: 1, limit: 25, pending: true });
	},
};
</script>

<style scoped>
.emoji-guilds {
	margin-bottom: 20px;
}
</style>
