<template>
	<div class="item-flex">
		<v-img
			v-if="!gift"
			class="item-img mr-3"
			contain
			:alt="item.id"
			:src="getImageSrc()"
			height="32"
			width="32"
		/>
		<v-img
			v-else
			class="item-img mr-3"
			contain
			:alt="item.id"
			:src="avatarUrl"
			height="32"
			width="32"
		/>
		<div class="item-text">
			<div class="font-weight-bold text-md-body-1">
				{{ item.text }}
			</div>
			<div class="font-weight-regular text--secondary text-sm-body-2">
				Quantity: {{ item.count }}
			</div>
		</div>
		<div class="font-weight-bold item-price">
			{{ price }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'CheckoutCartItem',
	props: {
		item: {
			type: Object,
			required: true,
		},
		gift: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		price() {
			return `$${this.item.price * this.item.count}.00`;
		},
		isMobile() {
			return this.$vuetify.breakpoint.width < 750;
		},
		avatarUrl() {
			return `https://cdn.discordapp.com/avatars/${this.item.user.id}/${this.item.user.avatar}.png`;
		},
	},
	methods: {
		getImageSrc() {
			console.log(this.item);
			if (this.item.icon) {
				return require(`@/assets/` + this.item.icon);
			} else if (this.item.emoji) {
				return this.getEmojiUrl(this.item.emoji);
			}
		},
	},
};
</script>

<style scoped>
.item-flex {
	display: flex;
	min-width: 300px;
	justify-content: space-between;
	padding-bottom: 30px;
}

.item-img {
	max-width: 32px;
}

.item-text {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: -7px;
}

.item-price {
	margin-top: 3px;
}
</style>
