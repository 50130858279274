<template>
	<div class="pet-info">
		<v-card>
			<v-card-title class="text-h3">{{ pet.name }}</v-card-title>
			<v-card-subtitle class="text-h5">{{ pet.alt }}</v-card-subtitle>
			<div class="pet-image">
				<v-img contain class="avatar" :src="pet.imageUrl" alt="avatar" />
			</div>
			<v-card-text class="text-h5 pet-users">
				<b>Stats</b>
				<div class="stat-row">
					<v-img
						src="https://cdn.discordapp.com/emojis/531620120410456064.png"
						class="stat-icon"
						max-height="30"
						max-width="30"
					/>
					{{ pet.hp }}
					<div class="stat-spacer"></div>
					<v-img
						src="https://cdn.discordapp.com/emojis/531616155450998794.png"
						class="stat-icon"
						max-height="30"
						max-width="30"
					/>
					{{ pet.att }}
					<div class="stat-spacer"></div>
					<v-img
						src="https://cdn.discordapp.com/emojis/531616156222488606.png"
						class="stat-icon"
						max-height="30"
						max-width="30"
					/>
					{{ pet.pr }}
					<div class="stat-spacer"></div>
					<v-img
						src="https://cdn.discordapp.com/emojis/531620120976687114.png"
						class="stat-icon"
						max-height="30"
						max-width="30"
					/>
					{{ pet.wp }}
					<div class="stat-spacer"></div>
					<v-img
						src="https://cdn.discordapp.com/emojis/531616156231139338.png"
						class="stat-icon"
						max-height="30"
						max-width="30"
					/>
					{{ pet.mag }}
					<div class="stat-spacer"></div>
					<v-img
						src="https://cdn.discordapp.com/emojis/531616156226945024.png"
						class="stat-icon"
						max-height="30"
						max-width="30"
					/>
					{{ pet.mr }}
				</div>
				<b>Description</b>
				<span style="white-space: pre">{{ pet.description }}</span>
				<b>By</b>
				<div>
					{{ pet.by.globalName }} {{ pet.by.username }} {{ pet.by.discordId }}
				</div>
				<b>Creators</b>
				<div v-for="user in pet.creators" :key="'c' + user.discordId">
					{{ user.globalName }} {{ user.username }} {{ user.discordId }}
				</div>
				<b>Gifts</b>
				<div v-for="user in pet.gifts" :key="'g' + user.discordId">
					{{ user.globalName }} {{ user.username }} {{ user.discordId }}
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<span class="my-3 mx-3" v-if="isPending">
					<v-btn
						:loading="loading"
						color="error"
						@click="askReason = true"
						class="mx-2"
					>
						Decline
					</v-btn>
					<v-btn :loading="loading" color="success" @click="approve">
						Approve
					</v-btn>
				</span>
				<span v-else-if="pet.success">
					<b>APPROVED</b>
				</span>
				<span v-else-if="pet.decline">
					<b>DECLINED:</b> {{ pet.declineReason }}
				</span>
			</v-card-actions>
		</v-card>
		<v-dialog v-model="askReason" width="min-content" height="min-content">
			<v-card width="500">
				<v-card-title> Please give a decline reason </v-card-title>
				<v-card-text>
					<v-text-field
						v-model="declineReason"
						clearable
						label="Decline Reason"
						outlined
						hide-details
					>
					</v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						:loading="loading"
						color="error"
						@click="decline"
						:disabled="!declineReason"
					>
						Decline
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'AdminPetsInfo',
	components: {},
	props: {
		pet: {
			type: Object,
		},
	},
	data: () => ({
		loading: false,
		askReason: false,
		declineReason: null,
	}),
	computed: {
		isPending() {
			return !this.pet.decline && !this.pet.success;
		},
	},
	methods: {
		async decline() {
			this.loading = true;
			try {
				await this.$store.dispatch('declineAdminPendingPets', {
					id: this.pet._id,
					reason: this.declineReason,
				});
				this.$emit('close');
			} catch (err) {
				console.error(err);
				const opt = {
					text: `Failed to decline\n\n${err}`,
					imgUrl: 'owo-cry.png',
				};
				await this.$modal(opt).showError();
			} finally {
				this.loading = false;
			}
		},
		async approve() {
			this.loading = true;
			try {
				await this.$store.dispatch('approveAdminPendingPets', this.pet._id);
				this.$emit('close');
			} catch (err) {
				console.error(err);
				const opt = {
					text: `Failed to approve\n\n${err}`,
					imgUrl: 'owo-cry.png',
				};
				await this.$modal(opt).showError();
			} finally {
				this.loading = false;
			}
		},
	},
	mounted() {},
};
</script>

<style scoped>
.pet-info {
	margin: 20px;
}

.pet-image {
	position: absolute;
	right: 16px;
	top: 16px;
	width: 250px;
	height: 250px;
}

.stat-row {
	display: flex;
	font-size: 1.5rem;
	color: white !important;
	align-items: center;
}

.stat-spacer {
	width: 20px;
}

.pet-users {
	display: flex;
	flex-direction: column;
}
</style>
